.taa-footer {
  text-align: center;
  width: 100%;
  border-top: 1px solid #edf1f7;
  color: #222b45;
  font-family: Open Sans,sans-serif;
  font-size: .9375rem;
  font-weight: 400;
  line-height: 1.25rem;
  padding: 1.25rem;
  -webkit-font-smoothing: antialiased;

  p {
    font-size: 11px;
    margin-bottom: 3px;
    color: #222b45;
    font-weight: 400;

    .link {
      margin-right: 10px;
    }

    a {
      color: #06d1df;
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 1280px) {
  .taa-footer {
    width: 100%;
  }
}
