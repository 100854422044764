.taa-content {
  width: 75%;
  margin: 5% auto 0;
  -webkit-font-smoothing: antialiased;

  .taa-header-section {
    width: 650px;
    margin: 40px auto;
    text-align: center;

    .taa-header-title {
      font-size: 34px;
      color: #000;
      font-weight: 500;
      line-height: 3rem;
    }

    .taa-header-subtitle {
      font-size: 18px;
      color: #555961;
      line-height: 1.8rem;
      margin-top: 10px;

      .taa-tooltip {
        color: #06d1df;
        cursor: help;
        border-bottom: 1px dotted #06D1DF;
      }
    }
  }

  .taa-form {
    .taa-form-additional {
      width: 600px;
      margin: 0 auto;

      .taa-card {
        margin-bottom: 23px;

        .taa-card-header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 5px;

          h4 {
            font-size: 13pt;
            font-weight: 600;
            line-height: 2rem;
          }
        }

        .taa-card-content {
          border: 1px solid #D9D9D9;
          background-color: #fff;
          padding: 2.5rem 1.8rem;
          border-radius: 1rem;

          @extend .form-label
        }

        .taa-card-footer {
          width: 600px;
          width: auto;
        }

        .taa-card-remove-btn-container {
          background-color: transparent;
          border-color: transparent;
          border-style: solid;
          border-width: 0.0625rem;
          border-radius: 0.25rem;
          cursor: pointer;
          padding: 0.5625rem 0.5625rem;
        }
      }
    }

    .taa-form-content {
      width: 600px;
      margin: 0 auto;
      background-color: #fff;
      padding: 40px 57px;
      border-radius: 1rem;

      @extend .form-label;

      .taa-data-share-disclosure {
        font-size: 12px;
        margin-bottom: 15px;
        font-weight: 400;
        line-height: 17.4px;
        color: #6D6e78;

        a {
          color: #06d1df;
          text-decoration: underline;
        }
      }

      .taa-agreement-container {
        background-color: #efeff5;
        font-style: italic;
        font-size: 15px;
        cursor: pointer;
        line-height: 25px;
        padding: 16px;
        font-weight: 400;

        a {
          color: #06d1df;
          text-decoration: underline;
        }
      }
    }

    .taa-form-review-content {
      width: 800px;
      margin: 0 auto;

      .taa-form-review-item-container {
        font-size: 18px;
        font-weight: 400;
        line-height: 1.25rem;
        border: none;
        color: #555961;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        border-radius: 0.8rem;
        box-shadow: 0 4px 4px #c6c6dd40;
        margin-bottom: 18px;

        .taa-form-review-item {
          padding: 0 50px;

          .taa-form-review-item-header {
            font-size: 15px;
            font-weight: 600;
            line-height: 28px;
            color: #000;

            span {
              font-weight: 500;
            }
          }

          .taa-form-entity-label {
            span {
              font-weight: 400;
              font-size: 16px;
            }
          }
        }
      }

      .taa-review-quote-container {
        background-color: #100a29;
        padding: 46px 34px;
        border-radius: 1.5rem;

        .taa-review-quote-header {
          text-align: center;

          h2 {
            font-size: 36px;
            color: #fff;
            line-height: 30px;
            font-weight: 400;

            span {
              font-size: 18px;
              line-height: 25px;
              color: #fff;
              font-weight: 400;
              letter-spacing: -.425138px;
            }
          }
        }

        hr {
          border-top: 1px solid #ffffff;
          margin-bottom: 30px;
          margin-top: 30px;
          opacity: .25;
        }

        .taa-review-quote-content {
          color: #fff;
          font-size: 18px;
          font-weight: 400;
          margin-top: 1rem;
        }

        .taa-review-quote-btns {
          margin-top: 20px;
        }
      }
    }

    .taa-content-text {
      font-size: 16pt;
      line-height: 1.8rem;

      h2 {
        font-weight: 500;
        font-size: 20px;
        margin: 40px 0 20px;
        color: #000;
        line-height: 2.5rem;
      }
    }
  }

  .taa-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
}

.ta-tooltip-container {
  p {
    color: #222b45;
    font-size: .9375rem;
    font-weight: 400;
    line-height: 1.25rem;
    margin-bottom: 1rem;
  }

  ul {
    margin-top: 0;
    margin-bottom: 1rem;
    padding-left: 2rem;

    li {
      color: #222b45;
      font-family: Open Sans, sans-serif;
      font-size: .9375rem;
      font-weight: 400;
      line-height: 1.25rem;
      display: list-item;
      list-style-type: disc;
    }
  }
}


//Reuse style
.form-label {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0.5rem;
  line-height: 28px;
  color: #000;
}

// Entry page style
.entry-header-section {
  margin: 40px auto;
  text-align: center;

  .taa-header-title {
    font-size: 36px;
    color: #000;
    font-weight: 500;
    line-height: 3rem;
  }

  .taa-header-subtitle {
    font-size: 18px;
    color: #555961;
    line-height: 1.8rem;
    margin-top: 10px;
  }
}

// Mobile styling
@media screen and (max-width: 768px) {
  .taa-content {
    width: 100%;
    margin: 10px auto 0;

    .taa-header-section {
      margin: 20px auto;
      width: 100%;

      .taa-header-title {
        font-size: 25px;
        line-height: 2.3rem;
      }

      .taa-header-subtitle {
        font-size: 16px;
        padding: 0 10px;
      }
    }

    .taa-form {
      .taa-form-additional {
        width: 100%;

        .taa-card {
          .taa-card-content {
            width: 100%;
            padding: 20px;
          }

          .taa-card-remove-btn-container {
            background-color: transparent;
            border-color: transparent;
            border-style: solid;
            border-width: 0.0625rem;
            border-radius: 0.25rem;
            cursor: pointer;
            padding: 0.5625rem 0.5625rem;
          }
        }
      }

      .taa-form-content {
        width: 100%;
        padding: 20px;
      }

      .taa-form-review-content {
        width: 100%;
      }
    }
  }
}

