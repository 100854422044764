.taa-quote-desktop-container {
  background-color: #100a29;
  color: #fff;
  position: absolute;
  right: 0;
  padding: 50px 39px;
  width: 313px;
  border-radius: 20px 0 0 20px;
  font-size: 17px;
  font-weight: 400;
}

.accordion-enter {
  opacity: 0;
  transform: scaleY(0);
}

.accordion-enter-active {
  opacity: 1;
  transform: scaleY(1);
  transition: opacity 300ms, transform 300ms;
}

.accordion-exit {
  opacity: 1;
  transform: scaleY(1);
}

.accordion-exit-active {
  opacity: 0;
  transform: scaleY(0);
  transition: opacity 300ms, transform 300ms;
}
